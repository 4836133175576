// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._458c4c0ce8ac5f5d4706020a31e2074e-scss{background:var(--white-light);display:grid;padding:15px;margin:5px;grid-template-rows:repeat(2, -webkit-max-content);grid-template-rows:repeat(2, max-content);flex-direction:column;align-items:center;justify-content:center;border-radius:10px;word-break:break-word}._458c4c0ce8ac5f5d4706020a31e2074e-scss .e62d4ce8b64499b34ed0faad14d7f2bd-scss{font-weight:bold;text-align:center;font-size:20px;margin-bottom:5px}._458c4c0ce8ac5f5d4706020a31e2074e-scss ._839d5279d47a2dcc244f6f7df7b30a5c-scss{margin:0;font-size:14px}\n", ""]);
// Exports
exports.locals = {
	"container": "_458c4c0ce8ac5f5d4706020a31e2074e-scss",
	"title": "e62d4ce8b64499b34ed0faad14d7f2bd-scss",
	"text": "_839d5279d47a2dcc244f6f7df7b30a5c-scss"
};
module.exports = exports;
