import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ banner: { title, text } }) => {
  useStyles(classes);

  return (
    <div className={classes.container}>
      <span className={classes.title}> {title} </span>
      <p className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
