import React, { useEffect, useState } from 'react';

import SeoTextBanner from 'components/shared/seoTextBanners/seoTextBanner';
import Schemaorg from 'components/shared/schemaorg';

import { useApplication } from 'hooks/application';

import useAxios from 'hooks/axios';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ seoTextBox, endpoint, feature = false }) => {
    useStyles(classes);
    const axios = useAxios();
    const [data, setData] = useState([]);
    const { culture, country, idPv } = useApplication();

    const mapData = banners => {
        return banners.map(banner => ({
            title: banner.Titulo,
            text: banner.Texto
        }));
    };

    useEffect(() => {
        if (feature) {
            setData(seoTextBox);
        }
    }, [seoTextBox]);

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get(`${endpoint}?idPv=${idPv}&lang=${culture.iso_code}&pais=${country.url}`)
            if (res.data) {
                const mappedData = mapData(res.data);
                setData(mappedData);
            }
        }

        if (!feature) getData();
    }, []);

    return (
        data?.length > 0 &&
        <div className={classes.container}>
            <Schemaorg data={data} />
            {
                data.map((data, index) =>
                    <SeoTextBanner data-testid='seoTextBanner' key={index} banner={data} />
                )
            }
        </div>
    )
}
