import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
    const generateQuestions = questions => 
        questions.map(({title, text}) => (
            {
                '@type': 'Question',
                name: title,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: text
                }
            }
        ))

    const generateJsonLd = data => {
        const jsonData = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: generateQuestions(data)
        }

        return JSON.stringify(jsonData);
    } 

    return (
        <Helmet>
            {
                <script type='application/ld+json'>
                    { generateJsonLd(data) }
                </script>
            }
        </Helmet>
    );
}